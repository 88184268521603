<template>
  <div>
    <Actions :show="show" :view="view" :loadData="loadData" :gridApi="gridApi"  :setShow="setState" :modal="{interface: I_ELHUNYTAK, data, title: 'Elhunyt'}"/>
    <div class="d-flex">
      <div class="col-lg-7 d-flex pl-4 mt-4">
          <input v-model="model.vezeteknev" placeholder="Vezeteknev" class="form-control mr-3">
          <input v-model="model.keresztnev" placeholder="Keresztnev" class="form-control mr-3">
        <b-button variant="success" @click="filterData" class="mr-3">
          Keresés
        </b-button>
        <b-button variant="success" @click="reset">
          Visszallitas
        </b-button>
      </div>
    </div>
    <ag-grid-vue
      style="width: 100%; height: 60vh;"
      class="ag-theme-alpine mt-4"
      :columnDefs="columnDefs"
      :modules="modules"
      :defaultColDef="defaultColDef"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      :rowData="filteredData"
      :rowHeight="50"
      :localeText="AG_GRID_LOCALE_HU"
      rowSelection="multiple">
    </ag-grid-vue>
  </div>
</template>

<script>
import {Grid} from "../mixins/Grid";
import Actions from "../components/Grid/Actions";
import {I_ELHUNYTAK} from "../constants/interfaces";

export default {
  name: "Elhunytak",
  components: {Actions},
  mixins: [Grid],
  data(){
    return{
      I_ELHUNYTAK,
      model: {
        vezeteknev: '',
        keresztnev: ''
      }
    }
  },
  async beforeMount() {
    this.columnDefs = [...this.columnDefs,...I_ELHUNYTAK.fields];
    await this.loadData();
  },
  watch: {
  },
  methods: {
    async filterData(){
      const first = `vezeteknev=${this.model.vezeteknev}`
      const second = `keresztnev=${this.model.keresztnev}`
      const query = '/elhunytaks?' + (this.model.vezeteknev ? this.model.keresztnev ? first + '&' + second : first : this.model.keresztnev ? this.model.vezeteknev ? second + '&' + first : second : '')
      const {data} = await this.axios.get(query + '&_limit=-1')
      this.filteredData = data.map(e=>{
        e.sirhely_parsed = e.sirhely.length ?  `${e.sirhely[0].sirhelytabla}, ${e.sirhely[0].sorszam}, ${e.sirhely[0].nyilvantaratas_szama ? e.sirhely[0].nyilvantaratas_szama : '-'}` :e.sirhely
        return e
      });    },
    reset(){
      this.filteredData = this.rowData
      this.model = {
        vezeteknev: '',
        keresztnev: ''
      }
    },
    async loadData(instance = null,load = ''){
      switch (load){
        case "add":{
          window.elhunytak.push(instance?.data)
          break
        }
        case "edit":{
          window.elhunytak = window.elhunytak.map(e => {
            if(e.id === instance.data?.id){
              e = instance.data
            }
            return e
          })
          break
        }
        case "delete":{
          window.elhunytak = window.elhunytak.filter(e=>e.id !== instance)
          break
        }
      }
      this.rowData =  window.elhunytak;
      this.rowData = this.rowData.map(e=>{
        e.sirhely_parsed = e.sirhely.length ?  `${e.sirhely[0].sirhelytabla}, ${e.sirhely[0].sorszam}, ${e.sirhely[0].nyilvantaratas_szama ? e.sirhely[0].nyilvantaratas_szama : '-'}` :e.sirhely
        return e
      })
      this.filteredData = this.rowData
      this.$toasted.show('Adatok frissitve', {
        duration: 2000,
      })

    }
  }
}
</script>

<style scoped>

</style>
